body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
}

.ms-Breadcrumb-item {
    color: white
}

.ms-Breadcrumb-overflowButton {
    color: white;
}

.ms-Breadcrumb-chevron {
    color: black;
}

.color-darkgray {
    background-color: #212121
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.iota-row {
    display: flex;
    align-items: stretch
}

.layout-root {
    width: 100%;
    z-index: 1;
    overflow: hidden;
    display: flex;
}

.iota-cell {
    margin: 8px;
    padding: 8px;
}

.app-bar {
    margin: 0;
    height: 100px;
    padding: 40px 40px 0 40px;
    color: white;
    opacity: 1;
    transition: 0.4s;
}

.hidden-app-bar {
    margin: -140px 0 0 0;
    height: 100px;
    padding: 40px 40px 0 40px;
    color: white;
    opacity: 1;
    transition: 0.4s;
}

.left-nav-title {
    color: black;
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.1);
}

.left-nav {
    margin-left: 0;
    min-height: 100vh;
    width: 64px;
    min-width: 64px;
    background-color: white;
    transition: 0.4s;
}

.hidden-left-nav {
    margin-left: -64px;
    min-height: 100vh;
    width: 64px;
    min-width: 64px;
    background-color: white;
    transition: 0.4s;
}

.left-nav:hover {
    min-height: 100vh;
    width: 200px;
    min-width: 200px;
    background-color: white;
    transition: 0.4s;
}

.selected-left-nav-link-item {
    border-left: 2px solid rgb(0, 120, 212);
    background-color: rgba(0, 120, 212, 0.1);
}

.left-nav-link-item {
    color: black;
    display: flex;
    align-items: center;
}

.left-nav-link-item:hover {
    background-color: rgba(0, 120, 212, 0.1);
    display: flex;
    align-items: center;
    cursor: pointer;
}

.left-nav-icon {
    color: rgb(0, 120, 212);
    font-weight: bold;
    min-width: 64px;
    height: 64px;
}

.selected-link-item {
    width: 2px;
    height: 64px;
    background-color: rgb(0, 120, 212);
    flex: none;
}

.background-color-white {
    background-color: white !important;
}

.layout-content {
    min-height: calc(100vh - 104px);
    width: calc(100% - 64px);
    flex-grow: 1;
    background-color: #f4f4f4;
    transition: 0.4s;
}

.app-background-color {
    background-color: #f4f4f4;
}

.layout-content-children {
    flex-grow: 1;
    background-color: #f4f4f4;
    transition: 0.4s;
}

.iota-card-title {
    margin: 0;
    padding: 16px;
    transition: 0.4s;
    border-bottom: 1px solid #eee;
}

.iota-centered-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-fine-uploader-file-input-container {
    margin: 8px;
}

.react-fine-uploader-delete-button {
    background-color: rgba(0, 0, 0, 0);
    color: white;
    height: 0;
    width: 0;
    margin: 0;
    padding: 0;
}

.react-fine-uploader-cancel-button {
    padding: 0;
    border: 0;
}

.react-fine-uploader-file-progress-bar-container {
    bottom: 0;
    margin: 0;
}

.iota-three-dot {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.react-fine-uploader-gallery-total-progress-bar-container {
    width: calc(100% - 126px);
}

.react-fine-uploader-file-input-container {
    margin: 0;
}

.delete-button {
    background-color: #EF5350 !important;
    color: white !important;
}

.copy-button {
    background-color: #42A5F5 !important;
    color: white !important;
}

.cut-button {
    background-color: #26A69A !important;
    color: white !important;
}

.paste-button {
    background-color: #FB8C00 !important;
    color: white !important;
}

.decline-button {
    background-color: #EEEEEE !important;
    color: white !important;
}

.save-button {
    background-color: #0288D1 !important;
    color: black !important;
}

.disabled-button {
    background-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.26)
}

.iota-card {
    transition: 0.4s;
    overflow: hidden;
    min-height: 300px;
    width: 100%;
    max-width: 100% !important;
}

.iota-cell {
    /*margin-top: 8px;*/
}

.iota-dialog-content {
    width: 100%;
}

.iota-dialog {
    width: 90% !important;
    max-width: 800px !important;
}

.iota-fullwidth-dialog {
    width: 90% !important;
    max-width: 90% !important;
}

.iota-fullwidth {
    width: 100%
}

.iota-fileupload-card {
    transition: 0.4s;
    overflow: hidden;
    width: 100%;
    border: 1px dashed black;
}

.left-nav-link-parent {
    list-style: none;
    padding: 0;
    margin: 0;
}

.iota-icon {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iota-white-icon {
    width: 24px;
    height: 24px;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
}

.iota-white-icon:hover {
    color: #ccc;
}

.ms-Panel-header {
    background-color: #006f94;
    margin: 0 !important;
    padding: 32px;
}

.ms-Panel-main {
    border: none !important
}

.ms-Panel-content {
    padding: 16px !important;
}

.iota-breadcrumb {
    margin: 0 !important;
    height: 55px;
    padding: 16px;
}

.iota-breadcrumb > div > ol > li > button {
    display: flex;
}

.iota-breadcrumb > div > ol > li > button > div {
    font-size: 16px;
}

.iota-detail-list {
    /*max-height: 500px;*/
    max-height: calc(100vh - 477px);
    transition: 0.4s;
    overflow: auto;
}

.iota-login-dialog-content {
    margin: 0;
    padding: 0;
}

.text-align-left {
    display: block;
    text-align: left;
}

.text-align-center {
    display: block;
    text-align: center;
}

.text-align-right {
    display: block;
    text-align: right;
}

pre {
    margin: 0
}

.iota-white-space-pre-line {
    white-space: pre-line !important;
}

.hover-expandable-card-title:hover {
    background-color: #fafafa !important;
    transition: 0.4s;
}

.iota-borderless {
    border: none !important;
}

.iota-fullwidth {
    width: 100%
}

.iota-cursor-pointer {
    cursor: pointer !important;
}

.iota-sticky {
    position: sticky !important;
    top: 0
}

.iota-attachment-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding: 8px;
    margin: 8px;
    transition: 0.4s;
}

.iota-attachment-list-item-buttons {
    opacity: 0;
    transition: 0.4s;
}

.iota-attachment-list-item:hover {
    background-color: #f4f4f4;
    transition: 0.4s;
}

.iota-attachment-list-item:hover .iota-attachment-list-item-buttons {
    background-color: #f4f4f4;
    opacity: 1;
    transition: 0.4s;
    cursor: pointer;
}

.iota-attachment-list-item:hover .iota-attachment-list-item-content-wrapper {
    background-color: #f4f4f4;
    opacity: 1;
    transition: 0.4s;
}

.sticky-breadCrumb {
    z-index: 1;
    top: 140px !important;
    transition: 0.4s;
}

.fullscreen-sticky-breadCrumb {
    z-index: 1;
    top: 0px !important;
    transition: 0.4s;
}

.sticky-app-commandbar {
    z-index: 1;
    top: 195px !important;
    background-color: #f4f4f4;
    box-shadow: 2px 1px 70px -20px black;
    transition: 0.4s;
}

.fullscreen-sticky-app-commandbar {
    z-index: 1;
    top: 53px !important;
    background-color: #f4f4f4;
    box-shadow: 2px 1px 70px -20px black;
    transition: 0.4s;
}

.login-background {
    background: radial-gradient(white, #0078d4);
    min-height: 100vh;
}

.iota-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.iota-notification-item {
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 300px;
    animation: 1s notification-in linear forwards;
    width: 300px;
    opacity: 0;
    transition: 0.4s;
}

.app-commandbar {
    transition: 0.4s;
}

.iota-notification-item-out {
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 0px;
    animation: 1s notification-out linear forwards;
    width: 300px;
    opacity: 1;
    transition: 0.4s;
}

@keyframes notification-in {
    from {
        margin-left: 300px;
        opacity: 0;
    }
    to {
        margin-left: 0;
        opacity: 1;
    }
}

@keyframes notification-out {
    from {
        margin-left: 0;
        opacity: 1;
    }
    to {
        margin-left: 300px;
        opacity: 0;
    }
}

.tm-cell {
    margin: 8px;
    padding: 8px;
}


.cursor-pointer {
    cursor: pointer;
}

.cursor-pointer-shadow {
    transition: 0.4s;
}

.cursor-pointer-shadow:hover {
    cursor: pointer;
    transition: 0.4s;
    box-shadow: 0px 0px 28px -4px black;
}

.tm-card {
    background-color: white;
}

.tm-delete-button {
    background-color: #e81123 !important;
    color: white !important;
}

.tm-delete-button:hover {
    background-color: #d80000 !important;
    color: white !important;
}

.tm-delete-button:active {
    background-color: #a80000 !important;
    color: white !important;
}

.tm-document-card {
    max-width: 10000px !important;
}